import { FC, FormEvent, useEffect, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import Breadcrumbs from '../../Components/Breadcrumbs';
import Button from '../../Components/Button';
import FormMessage from '../../Components/FormMessage';
import OrderInfo from '../../Components/OrderInfo';
import OrderSummary from '../../Components/OrderSummary';
import PaymentForm from '../../Components/PaymentForm';
import { IPaymentService } from '../../Services/PaymentService';
import { ICapthcaService } from '../../Services/CaptchaService';
import { usePayment } from '../../Context/PaymentContext';
import { useLoader } from '../../Context/LoaderContext';
import { useSite } from '../../Context/SiteContext';
import { buildAddress } from '../../Utils/buildAddress';
import SpinnerGif from '../../images/spinner.gif';
import { ReCaptcha } from 'react-recaptcha-google';
import React from 'react';
import { useFeatureFlag } from '../../Utils/appsettingsHelper';
import TagManager from 'react-gtm-module';

export interface IHome {
  theme?: DefaultTheme;
  paymentService: IPaymentService;
  captchaService : ICapthcaService;
}

const Spinner = () => <img src={SpinnerGif} />;
let token : string;

const ButtonContainer = styled.div(({ theme }) => {
  const { breakpoint } = theme;

  return `
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;


    @media screen and (min-width: ${breakpoint.phone}) {
      margin-bottom: 17px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
    }
  `;
});
const ReCaptchaContainer = styled.div(({ theme }) => {
  const { breakpoint } = theme;

  return `
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;


    @media screen and (min-width: ${breakpoint.phone}) {
      margin-bottom: 10px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
    }
  `;
});

const Home: FC<IHome> = ({ paymentService, captchaService }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const { baseSiteName, baseSiteUrl } = useSite();
  const { state, dispatch } = usePayment();
  const { customer, form, formRetry } = state;
  const { loaderDispatch, loaderState } = useLoader();
  const recaptchaRef : any = React.createRef<ReCaptcha>()


  const { billingAddress, email, shippingAddress } = customer;
  const captchaKey = process.env.REACT_APP_CAPTCHA_CLIENT_KEY;
  const { enabled: recpatchaEnabled } = useFeatureFlag(process.env.REACT_APP_CAPTCHA_ENABLED); // The feature flag key from App Config
  const { enabled: gtmEnabled } = useFeatureFlag(process.env.REACT_APP_GTM_ENABLED); // The feature flag key from App Config
  const { enabled: enablePaymentProviderAdyen} = useFeatureFlag(process.env.REACT_APP_PAYMENT_PROVIDER_ADYEN_ENABLED, true);
 
  const order = {
    contact: email,
    shipTo: buildAddress(shippingAddress),
    billTo: buildAddress(billingAddress),
  };


  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (loaderState.count > 0) {
      return;
    }

    dispatch({ type: 'clearErrors' });
    dispatch({ type: 'formRetry', data: false });

    const { errors, sanitized, valid } = paymentService.preparePayment(form, enablePaymentProviderAdyen);

    if (!valid && errors) {
      Object.entries(errors).forEach(([key, value]) => {
        
        dispatch({ type: 'formError', field: key, value });
      });
    } else {
      loaderDispatch('open');

      // validate recaptcha only when the form is valid. This would avoid unnecessary api calls
      if (recpatchaEnabled) {
        console.log('recpatcha is Enabled');
        if (!token) {
          retry();
          loaderDispatch('close');
          return;
        }
        else {
          try {
            const valid_token = await captchaService.validateToken(token);
            if (!valid_token) {
              retry('token got expired, Please wait & reevaluate Recaptcha.');
              loaderDispatch('close');
              return;
            }
          }
          catch (err) {
            const message = 'Error occurred while validating reCaptcha token, Please retry. ';
            console.error(err || message);
            retry(message);
            loaderDispatch('close');
            return;
          }
        }
      }

      paymentService
        .checkThreshold(state.customer.arhausId)
        .then(success => {
          if(success){
            return paymentService.buildRequest(sanitized, state.customer.arhausId);
          }
        })
        .then((request) => {
          return paymentService.processPayment(request, gtmEnabled);
        })
        .then((url) => {
          window.location.replace(url);
        })
        .catch((error) => {
          if (error === 'api failure') {
            window.location.replace('/error');
          } else {
            dispatch({ type: 'formRetry', data: true });
            setErrorMessage(error);
             
          }
        })
        .finally(() => loaderDispatch('close'));
    }
  };

  const retry = (error = null) => {
    dispatch({ type: 'formRetry', data: true });
    setErrorMessage(error || 'Please confirm you are a human');
    return;
  }

  const verifyCallback = (recaptchaToken) => {
    token = recaptchaToken;
    setErrorMessage('');// remove error message    
    if (!recaptchaToken)
      console.error('recaptchaToken is null or empty', recaptchaToken)
  }

  const addViewPaymentInfoEventToDataLayer = () =>{
    if(!gtmEnabled) return;
    const event = {
      dataLayer: {
        event: 'view_payment_info',
        data: {
          view_payment_info: {
            ecommerce: {
              value: state?.customer?.amount,
              currency: 'USD',
              payment_type: null,
            },
            shopify_id:state?.customer?.shopifyId,
          }
        }
      }
    }
    TagManager.dataLayer(event);
  }

  useEffect(() => {
      addViewPaymentInfoEventToDataLayer();
  }, [gtmEnabled])

  return (
    <>
      <Helmet>
        <meta name="description" content="Arhaus Payment" />
        <title>{baseSiteName}</title>
        <link rel="canonical" href={baseSiteUrl} />
      </Helmet>

      <Breadcrumbs />

      <form id="checkoutForm" onSubmit={handleSubmit}>
        <OrderInfo order={order} />

        <PaymentForm />

        <OrderSummary currency="USD" total={customer.amount} />
       {(recpatchaEnabled) &&  <ReCaptchaContainer>
          <ReCaptcha
             ref={recaptchaRef}
            sitekey={captchaKey}
            verifyCallback={verifyCallback}
          />
        </ReCaptchaContainer>}
        <ButtonContainer>
          <Button type="submit">{loaderState.count > 0 ? <Spinner /> : 'Place Order'}</Button>
        </ButtonContainer>
        {formRetry ? <FormMessage  aria-live="assertive" >{errorMessage}</FormMessage> : <div style={{padding: "20px"}}> </div>}
      </form>
    </>
  );
};

export default Home;
