import { FC, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Switch, Route } from 'react-router';

import ShopifyCustomerService from './Services/ShopifyCustomerService';
import PaymentService from './Services/PaymentService';
import { usePayment } from './Context/PaymentContext';
import { useLoader } from './Context/LoaderContext';

import PageContainer from './Components/PageContainer';
import PageHeader from './Components/PageHeader';
import GlobalStyles from './Components/GlobalStyles';
import Loader from './Components/Loader';

import Home from './Pages/Home';
import Invalid from './Pages/Invalid';

import arhausTheme from './theme';
import Cookies from 'universal-cookie';
import { loadReCaptcha } from 'react-recaptcha-google';
import CaptchaService from './Services/CaptchaService';
import Footer from './Components/PageFooter/Footer';
import { useFeatureFlag } from './Utils/appsettingsHelper';
import axios from 'axios';

const cookies = new Cookies();
const archargeString = cookies.get("ArchargeInstantApprove");
// axios.defaults.headers.common['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;

// // Add a request interceptor
// axios.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   config.headers.common['Ocp-Apim-Subscription-Key'] = '6c7b26b093d643dcbb39b1c8766f642a';
//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });

const App: FC = () => {
  const shopifyCustomerService = new ShopifyCustomerService();
  const paymentService = new PaymentService();
  const captchaService = new CaptchaService();
  const id = new URLSearchParams(useLocation().search).get('id');
  const { state, dispatch } = usePayment();
  const { loaderDispatch } = useLoader();
  const [pageStatus, setPageStatus] = useState('loading');
  const { enabled: footerEnabled } = useFeatureFlag(process.env.REACT_APP_FOOTER_ENABLED); // The feature flag key from App Config
  const { enabled: enabledPaymentProviderAdyen } = useFeatureFlag(process.env.REACT_APP_PAYMENT_PROVIDER_ADYEN_ENABLED, true); 
  useEffect(() => {
    loaderDispatch('open');
    if (!id) {
      loaderDispatch('close');
      return setPageStatus('error');
    }

    shopifyCustomerService
      .validateUniqueIdAsync(id, archargeString)
      .then(async (res) => {
        if (res.error) {
          setPageStatus('error');
          loaderDispatch('close');
          throw res.error;
        }

        dispatch({ type: 'updateCustomer', data: res.customerInfo });
        const plans = await paymentService.getPaymentPlans(res.customerInfo.amount);
        return plans;
      })
      .then((paymentPlans) => {
        if (paymentPlans.error) {
          setPageStatus('error');
          throw paymentPlans.error;
        }

        loadReCaptcha();
        dispatch({ type: 'updatePlans', data: paymentPlans.plans });
        loaderDispatch('close');
        //let's take a breath to finish the initialization of reCaptcha before enable Home component
        setTimeout(() => { setPageStatus('success'); }, 500);
      });
  }, [id]);

  useEffect(() => {
    if (state.form?.cardNumber) {
      const sanitized = paymentService.sanitizeCard(state.form.cardNumber);
      const cardInfo = paymentService.getCardInfo(sanitized, enabledPaymentProviderAdyen);

      if (cardInfo) {
        dispatch({ type: 'updateCard', data: cardInfo });
      } else {
        dispatch({ type: 'updateCard', data: undefined });
      }
    } else {
      dispatch({ type: 'updateCard', data: undefined });
    }
  }, [state.form?.cardNumber]);

  const pageContent = () => {
    switch (pageStatus) {
      case 'error': {
        return <Invalid />;
      }
      case 'success': {
        return <Home paymentService={paymentService} captchaService={captchaService} />;
      }
      default:
        return null;
    }
  };

  return (
    <HelmetProvider>
      <ThemeProvider theme={arhausTheme}>
        <GlobalStyles />
        <PageContainer>
          <PageHeader />

          <Switch>
            <Route exact path="/">
              {pageContent}
            </Route>
            <Route exact path="/error">
              <Invalid />
            </Route>
          </Switch>
          { footerEnabled && pageStatus != 'loading' && <Footer/> }
        </PageContainer>
        <Loader />
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
