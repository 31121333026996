import { FC, useEffect } from 'react';
import { useState } from "react";
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import BlockHeader from '../BlockHeader';
import CardIcon from '../CardIcon';
import HTMLBlock from '../HTMLBlock';
import Label from '../Label';
import Legend from '../Legend';
import SelectInput, { SelectOption } from '../SelectInput';
import TextInput from '../TextInput';
import ValidationMessage from '../ValidationMessage';
import { usePayment } from '../../Context/PaymentContext';
import Checkbox from '../CheckBox';
import { useFeatureFlag } from '../../Utils/appsettingsHelper';

const Block = styled.div(({ theme }) => {
  const { color } = theme;

  return `
    border: 1px solid ${color.grayscale[300]};
    border-radius:5px;
    font-size: 0.875rem;
  `;
});

const PaymentHeading = styled.div(({ theme }) => {
  const { breakpoint, color } = theme;

  return `
    background-color: white;
    border-bottom: 1px solid ${color.grayscale[300]};
    font-size: 14px;
    font-family: NeueHaasUnica;
    font-weight: 500;
    padding: 12px 10px;
    display: flex;
    flex-flow: row nowrap;
    jusitfy-content: space-between;
    font-size: 0.75rem;

    @media screen and (min-width: ${breakpoint.phone}) {
      padding: 17px 15px;
      font-size: 0.85rem;
    }
  `;
});

const PaymentHeadingText = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const PaymentHeadingCards = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
`;

const Card = styled(CardIcon)(({ theme }) => {
  const { breakpoint } = theme;

  return `
    height: 19px;
    width: 30px;
    margin-left: 3px;
  
    @media screen and (min-width: ${breakpoint.phone}) {
      height: 27px;
      width: 42px;
      margin-left: 5px;
    }
  `;
});

const Grid = styled.div(({ theme }) => {
  const { breakpoint } = theme;

  return `
    display: grid;
    grid-template-columns: 100%;
    padding: 0 10px 15px;
    grid-gap: 15px;
    font-size: 14px;
    font-family: NeueHaasUnica;
    font-size: 0.75rem;

    @media screen and (min-width: ${breakpoint.phone}) {
      padding: 0 19px 20px;
      grid-gap: 20px;
    }
  `;
});

const GridCell = styled.div`
  margin-top: 20px;
`;

const SplitCell = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const HalfCell = styled.div`
  width: calc(50% - 5px);
`;

const Terms = styled.div(({ theme }) => {
  const { color, type } = theme;

  return `
    color: ${color.grayscale[600]};
    font-weight: ${type.weight.regular};
    font-size: 14px;
    font-family: NeueHaasUnica;
    font-size: 0.75rem;
    margin: 0;
    padding: 0;
  `;
});

const SecurityNote = styled.div(({ theme }) => {
  const { color, type } = theme;
  return `
    font-size: 0.75rem;
    font-family: NeueHaasUnica;
    color: ${color.grayscale[550]};
    font-weight: ${type.weight.regular};
    font-family: NeueHaasUnica;
    padding-top: 1.5rem;
  `;
});

const PaymentForm: FC = () => {
  const { state, dispatch } = usePayment();
  const { enabled: enabledPaymentProviderAdyen } = useFeatureFlag(process.env.REACT_APP_PAYMENT_PROVIDER_ADYEN_ENABLED, true);

  const handlePlanChange = (value) => {
    dispatch({ type: 'formChange', field: 'paymentPlan', value: value });
    dispatch({ type: 'formChange', field: 'paymentPlanId', value: state.paymentPlans[value].code });
  };
  const [isCheckedArcharge, setIsCheckedArcharge] = useState(false);
  const [archargLastFour, setArchargeLastFour] = useState("");

  const handleChangeArcharge = (isChecked) => {
    setIsCheckedArcharge(isChecked);
    if (isChecked) {
      dispatch({ type: 'formChange', field: 'cardNumber', value: state.customer.archargeInstantCreditNumber });
      setArchargeLastFour(state.customer.archargeInstantCreditNumber.substr(state.customer.archargeInstantCreditNumber.length - 4, 4));
    }
    else 
    {
      state.form.cardNumber="";
      setArchargeLastFour("");
    }
  };

  const planOptionArray = state.paymentPlans.map((plan, index) => {
    return {
      label: plan.title,
      value: `${index}`,
    };
  });

  const planOptions: SelectOption[] = [
    {
      label: 'Select Payment Plan',
      value: 'placeholder',
      disabled: true,
    },
    ...planOptionArray,
  ];

  const monthOptionArray = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
  ];

  const monthOptions: SelectOption[] = [
    {
      label: 'Select Month',
      value: 'placeholder',
      disabled: true,
    },
    ...monthOptionArray,
  ];

  const current = new Date();
  const year = current.getFullYear();
  const yearOptionArray = [];

  for (let i = 0; i < 12; i++) {
    const value = year + i;
    yearOptionArray.push({ label: value, value });
  }

  const yearOptions: SelectOption[] = [
    {
      label: 'Select Year',
      value: 'placeholder',
      disabled: true,
    },
    ...yearOptionArray,
  ];

  useEffect(() => {
    const hasInstantCreditCard = state?.customer?.archargeInstantCreditNumber?.length > 0;
    if (hasInstantCreditCard)
      handleChangeArcharge(hasInstantCreditCard);
  }, [])

  return (
    <section>
      <BlockHeader>Payment</BlockHeader>
      <Block>
        <PaymentHeading>
          <PaymentHeadingText>Arhaus Payment</PaymentHeadingText>

          <PaymentHeadingCards>
            <Card cardType="archarge" style={{border: state.card?.type === 'archarge'? '1px solid orange':'' }} grayscale={state.card && state.card.type !== 'archarge'? true : false} />
            {!enabledPaymentProviderAdyen &&
              <>
                <Card cardType="visa" grayscale={state.card && state.card.type !== 'visa' ? true : false} />
                <Card cardType="mastercard" grayscale={state.card && state.card.type !== 'mastercard' ? true : false} />
                <Card cardType="discover" grayscale={state.card && state.card.type !== 'discover' ? true : false} />
                <Card cardType="amex" grayscale={state.card && state.card.type !== 'amex' ? true : false} />
              </>
            }
          </PaymentHeadingCards>
        </PaymentHeading>

        <Grid>

          {!isCheckedArcharge ? (
            <>
              <GridCell>
                <Label htmlFor="cardNumber">Credit Card Number*</Label>
                <NumberFormat
                  customInput={TextInput}
                  format={state.card?.mask || '#### #### #### ####'}
                  mask="_"
                  type="text"
                  name="cardNumber"
                  id="cardNumber"
                  autoComplete="off"
                  aria-describedby="cardNumberValidation"
                  value={state.form.cardNumber}
                  onValueChange={(e) => {
                    dispatch({ type: 'formChange', field: 'cardNumber', value: e.value });
                    dispatch({ type: 'formError', field: 'cardNumber', value: '' })
                  }}
                />
                <ValidationMessage id="cardNumberValidation" message={state.formError.cardNumber} />
              </GridCell>
            </>
          ) :
          (
              <>
                <GridCell>
                  <Label htmlFor="cardNumber">Credit Card Number*</Label>
                  <NumberFormat
                    customInput={TextInput}
                    format={'XXXX XXXX XXXX ####'}
                    mask="_"
                    type="text"
                    disabled={true}
                    name="archargeCardNumber"
                    id="archargeCardNumber"
                    autoComplete="off"
                    aria-describedby="cardNumberValidation"
                    value={archargLastFour}
                  />
                  <ValidationMessage id="cardNumberValidation" message={state.formError.cardNumber} />
                </GridCell>
              </>
            )}

          {state.customer?.archargeInstantCreditNumber && (
            <>
              <GridCell>
                <Checkbox
                  handleChange={(e) => handleChangeArcharge(e.target.checked)}
                  isChecked={isCheckedArcharge}
                  label=" Use Archarge Credit Card"
                  id={'chkCardNumber'}
                />
              </GridCell>
              {state.card?.type !== 'archarge' && (
              <>
              <Terms data-testid="terms">
                {state.form?.paymentPlan && <HTMLBlock html={state.paymentPlans[state.form.paymentPlan].description} />}
              </Terms>
              </>
              )}
            </>
          )}

          {state.card?.type === 'archarge' && (
            <>
              <GridCell>
                <Label htmlFor="paymentPlan">Payment Plan*</Label>
                <SelectInput
                  name="paymentPlan"
                  id="paymentPlan"
                  defaultValue="placeholder"
                  options={planOptions}
                  onChange={(e) => handlePlanChange(e.target.value)}
                  aria-describedby="paymentPlanValidation"
                />
                <ValidationMessage id="paymentPlanValidation" message={state.formError.paymentPlan} />
              </GridCell>
              <Terms data-testid="terms">
                {state.form?.paymentPlan && <HTMLBlock html={state.paymentPlans[state.form.paymentPlan].description} />}
              </Terms>
            </>
          )}

          {!enabledPaymentProviderAdyen && state.card && state.card.type !== 'archarge' && (
            <>
              <GridCell data-testid="ccInputs">
                <fieldset>
                  <Legend>Expiration Date*</Legend>
                  <SplitCell>
                    <HalfCell>
                      <SelectInput
                        aria-label="Expiration Month"
                        name="expMonth"
                        id="expMonth"
                        defaultValue="placeholder"
                        options={monthOptions}
                        autoComplete="off"
                        aria-describedby="expirationValidation"
                        onChange={(e) => dispatch({ type: 'formChange', field: 'expMonth', value: e.target.value })}
                      />
                    </HalfCell>
                    <HalfCell>
                      <SelectInput
                        aria-label="Expiration Year"
                        name="expYear"
                        id="expYear"
                        defaultValue="placeholder"
                        options={yearOptions}
                        autoComplete="off"
                        aria-describedby="expirationValidation"
                        onChange={(e) => dispatch({ type: 'formChange', field: 'expYear', value: e.target.value })}
                      />
                    </HalfCell>
                  </SplitCell>
                  <ValidationMessage id="expirationValidation" message={state.formError.expiration} />
                </fieldset>
              </GridCell>

              <GridCell>
                <SplitCell>
                  <HalfCell>
                    <Label htmlFor="cvv">Security Code*</Label>
                    <NumberFormat
                      customInput={TextInput}
                      format={state.card?.cvcLength?.indexOf(4) !== -1 ? '####' : '###'}
                      type="text"
                      name="cvv"
                      id="cvv"
                      autoComplete="off"
                      aria-describedby="cvvValidation"
                      onChange={(e) => dispatch({ type: 'formChange', field: 'cvv', value: e.target.value })}
                    />

                    <ValidationMessage id="cvvValidation" message={state.formError.cvv} />
                  </HalfCell>
                  <HalfCell>
                    <SecurityNote>
                      This is a 3 or 4 digit code on the front (American Express) or back (Visa, Mastercard, Discover)
                      of your credit card.
                    </SecurityNote>
                  </HalfCell>
                </SplitCell>
              </GridCell>
            </>
          )}
        </Grid>
      </Block>
    </section>
  );
};

export default PaymentForm;
